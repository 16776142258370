import React from 'react';
import './App.css';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDYwseiHRDfaMg-q7EbvQkCu9rRMysggzQ",
  authDomain: "griffenshu-website.firebaseapp.com",
  databaseURL: "https://griffenshu-website-default-rtdb.firebaseio.com",
  projectId: "griffenshu-website",
  storageBucket: "griffenshu-website.appspot.com",
  messagingSenderId: "337349494712",
  appId: "1:337349494712:web:5227abc04ac585dba9714d",
  measurementId: "G-ZKKZ40G2MN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {
  return (
    <div className="App">
      <header>
        <h1>My Personal Website</h1>
      </header>
      <main>
        <section>
          <h2>About Me</h2>
          <p>
            Add your personal introduction here. Talk about your background,
            skills, and interests.
          </p>
        </section>
        <section>
          <h2>Resume</h2>
          <p>Add your resume content here.</p>
          <a href="/path/to/your/resume.pdf" download>
            Download Resume
          </a>
        </section>
        <section>
          <h2>Projects</h2>
          <ul>
            <li>
              <h3>Project 1</h3>
              <p>Add project description here.</p>
            </li>
            <li>
              <h3>Project 2</h3>
              <p>Add project description here.</p>
            </li>
            {/* Add more projects as needed */}
          </ul>
        </section>
        <section>
          <h2>Contact</h2>
          <p>Add your contact information here.</p>
        </section>
      </main>
      <footer>
        <p>&copy; 2023 My Personal Website</p>
      </footer>
    </div>
  );
}

export default App;
